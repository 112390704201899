import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { fontSizePharmacyListedPrice, getPadding, ResponsiveLableSize } from '../../../common/commonFun';

// Register necessary Chart.js components and plugin
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const CoPayPharmacyListedPriceChart = () => {
  const data = {
    labels: [
      ['Below', 'listed', 'price'],
      ['Listed', 'price'],
      ['Above', 'listed', 'price'],
   ],
   datasets: [{
    label: 'Monthly Sales',
    data: [5, 80, 15],
    backgroundColor: 'rgba(107, 186, 189, 1)',
    borderWidth: 1,
    barThickness: 65,
 }]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: getPadding()
    },
   scales: {
    x: {
       grid: {
          display: false
       },
       ticks: {
          font: {
             family: 'Open Sans',
             weight: 400,
             size: ResponsiveLableSize(),
          },
          maxRotation: 0,
          autoSkip: false,
       },
       border: {
          color: '#858586',
       },
    },
    y: {
       beginAtZero: true,
       min: 0,
       max: 100,
       ticks: {
          stepSize: 20,
          font: {
             family: 'Open Sans',
             weight: 500,
             size: ResponsiveLableSize()
          }
       },
       border: {
          color: '#858586',
       },
       title: {
          display: true,
          text: 'Percentage',
          color: '#6D6F71',
          font: {
             size: 16,
             weight: '400',
          },
          position: 'right',
          rotation: 0,
          padding: 20,
       },
    }
 },
 plugins: {
    legend: {
       display: false,
    },
    datalabels: {
      anchor: (context) => {
         const value = context.dataset.data[context.dataIndex];
         const maxValue = Math.max(...context.chart.data.datasets[0].data);
         const percentage = Math.round((value / maxValue) * 100);
         return percentage > 10 ? 'end' : 'end'; // Adjust based on percentage
       },
       align: (context) => {
         const value = context.dataset.data[context.dataIndex];
         const maxValue = Math.max(...context.chart.data.datasets[0].data);
         const percentage = Math.round((value / maxValue) * 100);
         return percentage > 10 ? 'start' : 'end'; // Adjust based on percentage
       },
       color: (context) => {
         const value = context.dataset.data[context.dataIndex];
         const maxValue = Math.max(...context.chart.data.datasets[0].data);
         const percentage = Math.round((value / maxValue) * 100);
         return percentage > 10 ? '#fff' : '#2d9b9f'; // Change color dynamically
       },
       font: {
          family: 'Open Sans',
          size: fontSizePharmacyListedPrice(),
          weight: 700
       },
       offset: 4,
       formatter: (value, context) => {
          let total = context.chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
          let percentage = Math.round((value / total) * 100);
          if(percentage < 10) {
             return percentage + '%';
          }
          return percentage + '%';
       }
    }
 }
  };

  return (
    <div className="width-lg-40">
      <div className="card rounded-0 border-dark-700 h-100 py-4">
        <div className="card-body">
          <h3 className="text-secondary fw-bold fs-30 text-center">Pharmacy listed price</h3>
          <div>
            <Bar data={data} options={options} id="coPayPharmacyListedPrice" width="100%" height="430" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoPayPharmacyListedPriceChart;