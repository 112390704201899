import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { fontSizePieChart } from '../../../common/commonFun';

// Register Chart.js components and plugins
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const CoPayPharmaciesPerPatientChart = () => {
    const data = {
        labels: ['1', '2', '3', '4', '5+'],
        datasets: [
            {
                label: 'Pharmacies per Patient',
                data: [3, 6, 6, 10, 75],
                backgroundColor: ['rgba(46, 156, 158, 1)', 'rgba(87, 176, 178, 1)', 'rgba(107, 186, 186, 1)', 'rgba(150, 204, 207, 1)', 'rgba(191, 224, 227, 1)', ],
                borderWidth: 3,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 30,
                bottom: 20,
            },
        },
        plugins: {
            legend: {
                display: false, // Hide legend as per the original code
            },
            datalabels: {
                anchor: 'end', // Position labels at the end
                align: 'end',
                color: '#54667A',
                font: {
                    family: 'Open Sans',
                    size: fontSizePieChart(),
                    weight: 600
                },
                offset: 3,
                formatter: (value, context) => {
                    const total = context.chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
                    const percentage = Math.round((value / total) * 100);
                    return `${percentage}%`;
                },
            },
        },
    };

    return (
        <div className="col-lg-6">
            <div className="card rounded-0 border-dark-700 h-100 d-flex justify-content-between py-4">
                <div className="card-header bg-transparent border-0">
                    <h3 className="text-secondary fw-bold fs-30 text-center">Pharmacies per patient</h3>
                </div>
                <div className="card-body">
                    <div>
                        <Pie data={data} options={options} id="coPayPharmaciesPerPatient" className="pieChart" width="100%" />
                    </div>
                </div>
                <div className="card-footer bg-transparent border-0">
                    <div className="Legend d-flex justify-content-center flex-wrap">
                        <div className="copay-chart color-1 fw-bold text-dark-gray fs-20 pe-2 pb-2 pb-lg-0"><span></span>1</div>
                        <div className="copay-chart color-2 fw-bold text-dark-gray fs-20 pe-2 pb-2 pb-lg-0"><span></span>2</div>
                        <div className="copay-chart color-3 fw-bold text-dark-gray fs-20 pe-2 pb-2 pb-lg-0"><span></span>3</div>
                        <div className="copay-chart color-5 fw-bold text-dark-gray fs-20 pe-2 pb-2 pb-lg-0"><span></span>4</div>
                        <div className="copay-chart color-7 fw-bold text-dark-gray fs-20"><span></span>5+</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CoPayPharmaciesPerPatientChart;
