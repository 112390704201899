export const stateOptions = [
  { value: "ACT", label: "ACT" },
  { value: "NSW", label: "NSW" },
  { value: "NT", label: "NT" },
  { value: "QLD", label: "QLD" },
  { value: "SA", label: "SA" },
  { value: "TAS", label: "TAS" },
  { value: "VIC", label: "VIC" },
  { value: "WA", label: "WA" },
];

export const minLengthRegex = /.{8,}/;
export const capitalLetterRegex = /[A-Z]/;
export const numberRegex = /\d/;

export const NonEmptyStringCheck = (x) => {
  if (typeof x !== "string") {
    throw new Error("Input must be a string");
  }
  if (x.length === 0) {
    throw new Error("String must not be empty");
  }
};

export const startsWith04 = (value) => {
  if (value && value.substring(0, 2) === "04") {
    return true; // Validation passed if mobile number starts with "04"
  }
  return false; // Validation failed if mobile number doesn't start with "04"
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: "numeric", month: "long", year: "numeric" };
  return date.toLocaleDateString("en-GB", options);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};


export const currentDate = () =>{
  // Get current date
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed
  const currentDay = currentDate.getDate();
  return `${currentDay}/${currentMonth}/${currentYear}`
}

export const fontSizePieChart=()=> {
  if (window.innerWidth < 576) return 15;
  if (window.innerWidth < 768) return 16;
  if (window.innerWidth < 992) return 17;
  return 18;
}


export const getPadding =()=> {
  if(window.innerWidth < 480) {
     return {
        top: 30,
        right: 0,
        bottom: 0,
        left: -20
     };
  } else if(window.innerWidth < 768) {
     return {
        top: 30,
        right: 0,
        bottom: 0,
        left: 0
     };
  } else {
     return {
        top: 50,
        right: 10,
        bottom: 0,
        left: -10
     };
  }
}

export const ResponsiveLableSize=()=> {
  if(window.innerWidth < 576) return 13; 
  if(window.innerWidth < 768) return 14; 
  if(window.innerWidth < 992) return 15; 
  return 16; 
}
export const fontSizePatientEnrolled =()=> {
  if(window.innerWidth < 576) return 16; 
  if(window.innerWidth < 768) return 18; 
  if(window.innerWidth < 992) return 22; 
  return 26; 
}
export const fontSizeMonthlyChart =()=> {
  if(window.innerWidth < 576) return 11; 
  if(window.innerWidth < 768) return 12; 
  if(window.innerWidth < 992) return 14; 
  return 22; 
}

export const fontSizedaysDispensing =()=> {
  if(window.innerWidth < 576) return 16; 
  if(window.innerWidth < 768) return 18; 
  if(window.innerWidth < 992) return 22; 
  return 30; 
}


export const fontSizePharmacyListedPrice =()=> {
  if(window.innerWidth < 576) return 18; 
  if(window.innerWidth < 768) return 22; 
  if(window.innerWidth < 992) return 24; 
  return 26; 
}