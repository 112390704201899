import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { fontSizePieChart } from '../../../common/commonFun';

// Register Chart.js components and plugins
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const CliniciansPerPatientChart = () => {
  const data = {
    labels: ['1', '2', '3+'], // Add labels for clarity
    datasets: [
      {
        label: 'Clinicians per Patient',
        data: [2, 8, 90],
        backgroundColor: [
          'rgba(18, 66, 99, 1)',   // 1
          'rgba(89, 122, 145, 1)', // 2
          'rgba(207, 217, 224, 1)', // 3+
        ],
        borderWidth: 3,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 30,
        bottom: 20,
      },
    },
    plugins: {
      legend: {
        display: false, // Hides the default legend
      },
      datalabels: {
        anchor: 'end', // Position labels at the end
        align: 'end',
        color: '#54667A',
        font: {
          family: 'Open Sans',
          size: fontSizePieChart(),
          weight: 600
       },
        offset: 3,
        formatter: (value, context) => {
          const total = context.chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
          const percentage = Math.round((value / total) * 100);
          return `${percentage}%`;
        },
      },
    },
  };

  return (
    <div className="col-lg-6">
      <div className="card rounded-0 border-dark-700 h-100 d-flex justify-content-between py-4">
        <div className="card-header bg-transparent border-0">
          <h3 className="text-turquoise fw-bold fs-30 text-center">Clinicians per patient</h3>
        </div>
        <div className="card-body">
          <div>
            <Pie data={data} options={options} id="cliniciansPerPatient" className="pieChart" width="100%" />
          </div>
        </div>
        <div className="card-footer bg-transparent border-0">
          <div className="Legend d-flex justify-content-center flex-wrap">
            <div className="patient-gender-chart color-1 fw-bold text-dark-gray fs-20 pe-2 pb-2 pb-lg-0"><span></span>1</div>
            <div className="patient-gender-chart color-4 fw-bold text-dark-gray fs-20 pe-2 pb-2 pb-lg-0"><span></span>2</div>
            <div className="patient-gender-chart color-7 fw-bold text-dark-gray fs-20 pe-2 pb-2 pb-lg-0"><span></span>3+</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CliniciansPerPatientChart;
