import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { fontSizePieChart } from '../../../common/commonFun';

// Register Chart.js components and plugins
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const CoPayPatientGenderChart = () => {
  const data = {
    // labels: ['Female', 'Male', 'Non-binary', 'Prefer not to say'],
    datasets: [
      {
        label: 'Patient Gender Distribution',
        data: [61, 33, 2, 4],
        backgroundColor: ['rgba(46, 156, 158, 1)', 'rgba(87, 176, 178, 1)', 'rgba(130, 194, 196, 1)', 'rgba(191, 224, 227, 1)', ],
        borderWidth: 3,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 30,
        bottom: 20,
      },
    },
    plugins: {
      legend: {
        display: false, // Custom legend is implemented below
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        color: '#54667A',
        font: {
          family: 'Open Sans',
          size: fontSizePieChart(),
          weight: 600,
        },
        offset: 3,
        formatter: (value, context) => {
          const total = context.chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
          const percentage = Math.round((value / total) * 100);
          return `${percentage}%`;
        },
      },
    },
  };

  return (

    <div className="col-lg-6">
      <div className="card rounded-0 border-dark-700 h-100 d-flex justify-content-between py-4">
        <div className="card-header bg-transparent border-0">
          <h3 className="text-secondary fw-bold fs-30 text-center mt-1">Patient gender</h3>
        </div>
        <div className="card-body">
          <div>
          <Pie data={data} options={options}  id="copayPatientGender" className="pieChart" width="100%"/>
          </div>
        </div>
        <div className="card-footer bg-transparent border-0">
          <div className="Legend d-flex justify-content-start px-2 px-lg-5 flex-wrap">
            <div className="copay-chart color-1 fw-bold text-dark-gray fs-20 pe-2 pb-2"><span></span>Female</div>
            <div className="copay-chart color-2 fw-bold text-dark-gray fs-20 pe-2 pb-2"><span></span>Male</div>
            <div className="copay-chart color-5 fw-bold text-dark-gray fs-20 pe-2 pb-2"><span></span>Non-binary</div>
            <div className="copay-chart color-7 fw-bold text-dark-gray fs-20 pe-2"><span></span>Prefer not to say</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoPayPatientGenderChart;
