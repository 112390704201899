import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { fontSizePieChart } from '../../../common/commonFun';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const EnrolledCliniciansChart = () => {
  // Data for the pie chart
  const data = {
    labels: ['NSW', 'ACT', 'VIC', 'TAS', 'QLD', 'SA', 'WA', 'NT'],
    datasets: [
      {
        label: 'Enrolled Clinicians',
        data: [1, 23, 3, 36, 5, 23, 5, 5], // Data for each state
        backgroundColor: [
          'rgba(18, 66, 99, 1)',   // NSW
          'rgba(65, 105, 130, 1)',   // ACT
          'rgba(89, 122, 145, 1)',   // VIC
          'rgba(112, 143, 161, 1)',   // TAS
          'rgba(138, 161, 176, 1)',   // QLD
          'rgba(161, 178, 194, 1)',   // SA
          'rgba(184, 199, 209, 1)',   // WA
          'rgba(207, 217, 224, 1)',   // NT
        ],
        borderWidth: 3
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 30,
        bottom: 15,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false, // Disable the tooltip if you want to show only the percentage in the chart
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        color: '#54667A',
        font: {
          family: 'Open Sans',
          size: fontSizePieChart(),
          weight: '600',
        },
        offset: 3,
        formatter: (value, context) => {
          const total = context.chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
          const percentage = Math.round((value / total) * 100);
          return `${percentage}%`;
        },
      },
    },
  };

  return (
    <div className="width-lg-45">
    <div className="card rounded-0 border-dark-700 h-100 d-flex justify-content-between py-4">
        <div className="card-header bg-transparent border-0">
            <h3 className="text-turquoise fw-bold fs-30 text-center">Enrolled clinicians</h3>
        </div>
        <div className="card-body">
            <div>
            <Pie data={data} options={options}  className='pieChart' width="100%" height="370"/>
            </div>
        </div>
        <div className="card-footer bg-transparent border-0">
            <div className="row">
                <div className="col-3">
                    <div className="patient-gender-chart color-1 fw-bold text-dark-gray fs-18 pe-2 pb-2"><span></span>NSW</div>
                </div>
                <div className="col-3">
                    <div className="patient-gender-chart color-2 fw-bold text-dark-gray fs-18 pe-2 pb-2"><span></span>ACT</div>
                </div>
                <div className="col-3">
                    <div className="patient-gender-chart color-3 fw-bold text-dark-gray fs-18 pe-2 pb-2"><span></span>VIC</div>
                </div>
                <div className="col-3">
                    <div className="patient-gender-chart color-4 fw-bold text-dark-gray fs-18 pe-2 pb-2"><span></span>TAS</div>
                </div>
                <div className="col-3">
                    <div className="patient-gender-chart color-5 fw-bold text-dark-gray fs-18 pe-2 pb-2 pb-lg-0"><span></span>QLD</div>
                </div>
                <div className="col-3">
                    <div className="patient-gender-chart color-6 fw-bold text-dark-gray fs-18 pe-2 pb-lg-0"><span></span>SA</div>
                </div>
                <div className="col-3">
                    <div className="patient-gender-chart color-7 fw-bold text-dark-gray fs-18 pe-2 pb-lg-0"><span></span>WA</div>
                </div>
                <div className="col-3">
                    <div className="patient-gender-chart color-8 fw-bold text-dark-gray fs-18 pe-2 pb-lg-0"><span></span>NT</div>
                </div>
            </div>
        </div>
    </div>
</div>
  );
};

export default EnrolledCliniciansChart;
