// import React, { useEffect, useRef } from "react";
// import { Chart, registerables } from "chart.js";
// import ChartDataLabels from "chartjs-plugin-datalabels";

// Chart.register(...registerables, ChartDataLabels);

// const PatientGenderChart = ({ patientGenderData }) => {
//   const chartRef = useRef(null);
//   const chartInstance = useRef(null); // To hold the chart instance

//   useEffect(() => {
//     if (!Array.isArray(patientGenderData) || patientGenderData.length === 0) {
//       console.error("Invalid or missing patientGenderData");
//       return;
//     }

//     // Destroy existing chart instance if it exists
//     if (chartInstance.current) {
//       chartInstance.current.destroy();
//     }

//     // Create a new chart instance
//     chartInstance.current = new Chart(chartRef.current, {
//       type: "pie",
//       data: {
//         labels: ['Female', 'Male', 'Non-binary', 'Prefer not to say'],
//         datasets: [
//           {
//             label: "Patient Gender Distribution",
//             data: patientGenderData,
//             backgroundColor: [
//               "rgba(18, 66, 99, 1)", // Female
//               "rgba(66, 105, 130, 1)", // Male
//               "rgba(161, 178, 194, 1)", // Non-binary
//               "rgba(207, 217, 224, 1)", // Prefer not to say
//             ],
//             borderWidth: 3,
//           },
//         ],
//       },
//       options: {
//         responsive: true,
//         maintainAspectRatio: false,
//         layout: {
//           padding: {
//             top: 30,
//             bottom: 20,
//           },
//         },
//         plugins: {
//           legend: {
//             display: false,
//           },
//           datalabels: {
//             anchor: "end",
//             align: "end",
//             color: "#54667A",
//             font: {
//               size: 18,
//               weight: "600",
//             },
//             offset: 3,
//             formatter: (value, context) => {
//               const total = context.chart.data.datasets[0].data.reduce(
//                 (acc, val) => acc + val,
//                 0
//               );
//               const percentage = Math.round((value / total) * 100);
//               return `${percentage}%`;
//             },
//           },
//         },
//       },
//     });

//     // Cleanup the chart instance on component unmount
//     return () => {
//       if (chartInstance.current) {
//         chartInstance.current.destroy();
//       }
//     };
//   }, [patientGenderData]); // Re-run whenever `patientGenderData` changes

//   return (


//     <div className="col-lg-6">
//       <div className="card rounded-0 border-dark-700 h-100 d-flex justify-content-between py-4">
//         <div className="card-header bg-transparent border-0">
//           <h3 className="text-turquoise fw-bold fs-30 text-center mt-1">Patient gender</h3>
//         </div>
//         <div className="card-body">
//           <div>
//             <canvas ref={chartRef} id="patientGender" className="pieChart" width="100%"></canvas>
//           </div>
//         </div>
//         <div className="card-footer bg-transparent border-0">
//           <div className="Legend d-flex justify-content-start px-2 px-lg-5 flex-wrap">
//             <div className="patient-gender-chart color-1 fw-bold text-dark-gray fs-20 pe-2 pb-2"><span></span>Female</div>
//             <div className="patient-gender-chart color-2 fw-bold text-dark-gray fs-20 pe-2 pb-2"><span></span>Male</div>
//             <div className="patient-gender-chart color-5 fw-bold text-dark-gray fs-20 pe-2 pb-2"><span></span>Non-binary</div>
//             <div className="patient-gender-chart color-7 fw-bold text-dark-gray fs-20 pe-2"><span></span>Prefer not to say</div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PatientGenderChart;

import React, { useRef, useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { fontSizePieChart } from '../../../common/commonFun';

// Register Chart.js components and plugins
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const PatientGenderChart = () => {
  const [fontSize, setFontSize] = useState(fontSizePieChart());

  const data = {
    labels: ['Female', 'Male', 'Non-binary', 'Prefer not to say'],
    datasets: [
      {
        label: 'Patient Gender Distribution',
        data: [61, 33, 2, 4],
        backgroundColor: [
          'rgba(18, 66, 99, 1)', // Female
          'rgba(66, 105, 130, 1)', // Male
          'rgba(161, 178, 194, 1)', // Non-binary
          'rgba(207, 217, 224, 1)', // Prefer not to say
        ],
        borderWidth: 3,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 30,
        bottom: 20,
      },
    },
    plugins: {
      legend: {
        display: false, // Custom legend is implemented below
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        color: '#54667A',
        font: {
          family: 'Open Sans',
          size: fontSize,
          weight: 600,
        },
        offset: 3,
        formatter: (value, context) => {
          const total = context.chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
          const percentage = Math.round((value / total) * 100);
          return `${percentage}%`;
        },
      },
    },
  };

  // Update font size on window resize
  useEffect(() => {
    const handleResize = () => setFontSize(fontSizePieChart());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="col-lg-6">
      <div className="card rounded-0 border-dark-700 h-100 d-flex justify-content-between py-4">
        <div className="card-header bg-transparent border-0">
          <h3 className="text-turquoise fw-bold fs-30 text-center mt-1">Patient gender</h3>
        </div>
        <div className="card-body">
          <div>
            <Pie data={data} options={options} className="pieChart" />
          </div>
        </div>
        <div className="card-footer bg-transparent border-0">
          <div className="Legend d-flex justify-content-start px-2 px-lg-5 flex-wrap">
            <div className="patient-gender-chart color-1 fw-bold text-dark-gray fs-20 pe-2 pb-2">
              <span></span>Female
            </div>
            <div className="patient-gender-chart color-2 fw-bold text-dark-gray fs-20 pe-2 pb-2">
              <span></span>Male
            </div>
            <div className="patient-gender-chart color-5 fw-bold text-dark-gray fs-20 pe-2 pb-2">
              <span></span>Non-binary
            </div>
            <div className="patient-gender-chart color-7 fw-bold text-dark-gray fs-20 pe-2">
              <span></span>Prefer not to say
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientGenderChart;
