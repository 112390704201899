import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import { getPadding, ResponsiveLableSize } from '../../../common/commonFun';

const PatientEngagementChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const monthlyTreatmentC1 = new Chart(chartRef.current, {
        type: 'bar',
        data: {
          labels: ['SMS', 'Email', 'Nurse', 'Chatbot', 'Site'],
          datasets: [
            {
              label: 'Qtr 1',
              data: [23, 13, 3, 21, 39],
              backgroundColor: 'rgba(18, 66, 99, 1)',
              borderWidth: 0,
            },
            {
              label: 'Qtr 2',
              data: [20, 12, 4, 22, 41],
              backgroundColor: 'rgba(66, 105, 130, 1)',
              borderWidth: 0,
            },
            {
              label: 'Qtr 3',
              data: [21, 11, 4, 21, 40],
              backgroundColor: 'rgba(112, 143, 161, 1)',
              borderWidth: 0,
            },
            {
              label: 'Qtr 4',
              data: [22, 14, 5, 20, 38],
              backgroundColor: 'rgba(161, 178, 194, 1)',
              borderWidth: 0,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: getPadding()
          },
          scales: {
            x: {
              grid: {
                display: false
              },

              stacked: false,

              ticks: {
                font: {
                  family: 'Open Sans',
                  weight: 400,
                  size: ResponsiveLableSize(),
                },
              },
              border: {
                color: '#858586',
              }
            },
            y: {
              beginAtZero: true,
              min: 0,
              max: 45,
              ticks: {
                font: {
                  family: 'Open Sans',
                  weight: 500,
                  size: ResponsiveLableSize(),
                },
                stepSize: 5,
              },
              border: {
                color: '#858586',
              },

              title: {
                display: true,
                text: 'Percentage',
                color: '#6D6F71',
                font: {
                  family: 'Open Sans',
                  size: 16,
                  weight: '400',
                },
                position: 'right',
                rotation: 0,
                padding: 20,
              },
            }
          },
          plugins: {
            datalabels: {
              display: false, // This hides the data labels on the bars
            },
            legend: { display: false },
          },
        },
      });

      return () => monthlyTreatmentC1.destroy();
    }
  }, []);

  return (
    <div className="col-sm-12">
      <div className="card rounded-0 border-dark-700 h-100 d-flex justify-content-between py-4">
        <div className="card-header bg-transparent border-0">
          <h3 className="text-turquoise fw-bold fs-30 text-center">Patient engagement</h3>
        </div>
        <div className="card-body">
          <div>
            <canvas ref={chartRef} id="monthlyTreatmentC1" width="100%" height="450"></canvas>
          </div>
        </div>
        <div className="card-footer bg-transparent border-0">
          <div className="Legend d-flex justify-content-start flex-wrap ps-3 ps-lg-5">
            <div className="patient-gender-chart color-1 fw-bold text-dark-gray fs-18 pe-1 pb-2"><span></span>Qtr 1</div>
            <div className="patient-gender-chart color-2 fw-bold text-dark-gray fs-18 pe-1 pb-2"><span></span>Qtr 2</div>
            <div className="patient-gender-chart color-4 fw-bold text-dark-gray fs-18 pe-1 pb-2"><span></span>Qtr 3</div>
            <div className="patient-gender-chart color-5 fw-bold text-dark-gray fs-18 pe-1 pb-2"><span></span>Qtr 4</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientEngagementChart;
