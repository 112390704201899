import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { fontSizedaysDispensing, getPadding, ResponsiveLableSize } from '../../../common/commonFun';

// Register necessary Chart.js components and plugin
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const DaysDispensingChart = () => {
  const data = {
    labels: [
      ['Average'],
      ['NSW /', 'ACT'],
      ['VIC /', 'TAS'],
      ['QLD'],
      ['SA'],
      ['WA'],
      ['Top 10', 'Clinicians'],
    ],
    datasets: [
      {
        label: 'Monthly Sales',
        data: [32, 35, 31, 32, 29, 33, 28],
        backgroundColor: 'rgba(90, 123, 146, 1)', // Bar color
        borderWidth: 1
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: getPadding()
   },
    scales: {
      x: {
        grid: {
          display: false, // Hide vertical grid lines
        },
        ticks: {
          font: {
             family: 'Open Sans',
             weight: 400,
             size: ResponsiveLableSize(),
          },
          maxRotation: 0,
          autoSkip: false,
       },
       border: {
          color: '#858586',
       },
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: 40, // Adjust based on your data
        ticks: {
          stepSize: 5,
          font: {
             family: 'Open Sans',
             weight: 500,
             size: ResponsiveLableSize()
          }
        },
        border: {
          color: '#858586',
        },
        title: {
          display: true,
          text: 'Days', // Vertical title text
          color: '#6D6F71',
          font: {
            size: 16,
            family: 'Open Sans',
            weight: 400,
         },
          position: 'right',
          rotation: 0,
          padding: 20,
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      datalabels: {
        anchor: 'end', // Position labels at the end of the bars
        align: 'start', // Align label at the start of the bar
        color: '#fff', // Label color
        font: {
          family: 'Open Sans',
          size: fontSizedaysDispensing(),
          weight: 700
       },
        offset: 4, // Space between label and bar
        formatter: (value, context) => {
          let maxValue = Math.max(...context.chart.data.datasets[0].data);
          let percentage = Math.round(value);
          return percentage;
        },
      },
    },
  };

  return (

    <div className="width-lg-60">
      <div className="card rounded-0 border-dark-700 h-100 py-4">
        <div className="card-body">
          <h3 className="text-turquoise fw-bold fs-30 text-center">Days between dispensing</h3>
          <div>
            <Bar data={data} options={options} width="100%" height="400" />
            {/* <canvas id="daysDispensing" width="100%" height="400"></canvas> */}
          </div>
        </div>
        {/* <div className="card-footer bg-transparent border-0">
          <div className="Legend d-flex justify-content-center">
            <p className="mb-0 text-gray">*Define the highest number of enrolled patients</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default DaysDispensingChart;
