import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { fontSizePieChart } from '../../../common/commonFun';

// Register the necessary components for Chart.js and the data labels plugin
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const NonMetroPatientSupportChart = () => {
  const data = {
    labels: ['Psychologist', 'Physiotherapist', 'Dietician'],
    datasets: [
      {
        label: 'Non-Metro Patient Support',
        data: [30, 50, 20],
        backgroundColor: [
          'rgba(18, 66, 99, 1)',   // Psychologist
          'rgba(89, 122, 145, 1)',   // Physiotherapist
          'rgba(184, 199, 209, 1)',   // Dietician
        ],
        borderWidth: 3,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 30,
        bottom: 20,
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      datalabels: {
        anchor: 'end', // Position labels at the end of each slice
        align: 'end',
        color: '#54667A',
        font: {
          family: 'Open Sans',
          size: fontSizePieChart(),
          weight: 600
       },
        offset: 0,
        formatter: (value, context) => {
          const total = context.chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
          const percentage = Math.round((value / total) * 100);
          return percentage + '%'; // Show percentage
        },
      },
    },
  };

  return (
    <div className="col-lg-6">
      <div className="card rounded-0 border-dark-700 h-100 d-flex justify-content-between py-4">
        <div className="card-header bg-transparent border-0">
          <h3 className="text-turquoise fw-bold fs-30 text-center">Non-metro patient support</h3>
        </div>
        <div className="card-body">
          <div>
            <Pie data={data} options={options} id="nonMetroPatientSupport" className="pieChart" width="100%" />
          </div>
        </div>
        <div className="card-footer bg-transparent border-0">
          <div className="Legend d-flex justify-content-center flex-wrap">
            <div className="patient-gender-chart color-1 fw-bold text-dark-gray fs-20 pe-2 pb-2 pb-lg-0"><span></span>Psychologist</div>
            <div className="patient-gender-chart color-3 fw-bold text-dark-gray fs-20 pe-2 pb-2 pb-lg-0"><span></span>Physiotherapist</div>
            <div className="patient-gender-chart color-6 fw-bold text-dark-gray fs-20"><span></span>Dietician</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NonMetroPatientSupportChart;
