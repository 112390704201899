import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { fontSizePatientEnrolled, getPadding, ResponsiveLableSize } from '../../../common/commonFun';

// Register required Chart.js components and plugins
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const PatientsEnrolledPerClinicianChart = () => {
  const data = {
    labels: [
      ['1', 'Patient'],
      ['2', 'Patients'],
      ['3', 'Patients'],
      ['4', 'Patients'],
      ['5+', 'Patients'],
    ],
    datasets: [
      {
        label: 'Clinicians',
        data: [15, 20, 20, 25, 20],
        backgroundColor: 'rgba(90, 123, 146, 1)', // Bar color
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: getPadding(),
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide vertical grid lines
        },
        ticks: {
          font: {
            family: 'Open Sans',
            weight: 400,
            size: ResponsiveLableSize(),
          },
          autoSkip: false,
        },
        border: {
          color: '#858586',
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: 30, // Adjust based on your data
        ticks: {
          stepSize: 5,
          font: {
            family: 'Open Sans',
            weight: 500,
            size: ResponsiveLableSize(),
          },
        },
        border: {
          color: '#858586',
        },
        title: {
          display: true,
          text: 'Percentage',
          color: '#6D6F71',
          font: {
            family: 'Open Sans',
            weight: 400,
            size: 16,
          },
          position: 'right',
          rotation: 0,
          padding: 20,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'end',
        labels: {
          boxHeight: 20,
          boxWidth: 20,
          padding: 0,
          font: {
            family: 'Open Sans',
            size: 16,
            weight: 400,
          },
          color: '#54667A',
        },
      },
      datalabels: {
        anchor: 'end',
        align: 'start',
        color: '#fff',
        font: {
          family: 'Open Sans',
          size: fontSizePatientEnrolled(),
          weight: 700,
        },
        offset: 10,
        formatter: (value, context) => {
          let total = context.chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
          let percentage = Math.round((value / total) * 100);
          return `${percentage}%`;
        },
      },
    },
  };

  return (
    <div className="width-lg-55">
      <div className="card rounded-0 border-dark-700 h-100 py-4">
        <div className="card-header bg-transparent border-0">
          <h3 className="text-turquoise fw-bold fs-30 text-center">
            Patients enrolled per <br /> clinician
          </h3>
        </div>
        <div className="card-body">
          <Bar data={data} options={options} width="100%" height="440" />
        </div>
      </div>
    </div>
  );
};

export default PatientsEnrolledPerClinicianChart;
