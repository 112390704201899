import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { fontSizePieChart } from '../../../common/commonFun';

// Register Chart.js components and plugins
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const CoPayEnrolledCliniciansChart = () => {
  const data = {
    labels: ['NSW', 'ACT', 'VIC', 'TAS', 'QLD', 'SA', 'WA', 'NT'],
    datasets: [
      {
        label: 'Patient Gender Distribution',
        data: [1, 23, 3, 36, 5, 22, 5, 5],
        backgroundColor: ['rgba(46, 156, 158, 1)', 'rgba(87, 176, 178, 1)', 'rgba(107, 186, 189, 1)', 'rgba(130, 194, 196, 1)', 'rgba(150, 204, 207, 1)', 'rgba(171, 214, 217, 1)', 'rgba(191, 224, 227, 1)', 'rgba(214, 235, 235, 1)', ],
        borderWidth: 3
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 30,
        bottom: 15
      },
    },
    plugins: {
      legend: {
        display: false, // Custom legend is implemented below
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        color: '#54667A',
        font: {
          family: 'Open Sans',
          size: fontSizePieChart(),
          weight: 600,
        },
        offset: 3,
       formatter: (value, context) => {
        const total = context.chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
        const percentage = Math.round((value / total) * 100);
        return percentage + '%'; // Display percentage for each slice
      },
      },
    },
  };

  return (

    <div className="width-lg-45">
      <div className="card rounded-0 border-dark-700 h-100 d-flex justify-content-between py-4">
        <div className="card-header bg-transparent border-0">
          <h3 className="text-secondary fw-bold fs-30 text-center">Enrolled clinicians</h3>
        </div>
        <div className="card-body">
          <div>
            <Pie data={data} options={options} id="copayEnrolledClinicians" className="pieChart" width="100%" />
          </div>
        </div>
        <div className="card-footer bg-transparent border-0">
          <div className="row">
            <div className="col-3">
              <div className="copay-chart color-1 fw-bold text-dark-gray fs-18 pe-2 pb-2"><span></span>NSW</div>
            </div>
            <div className="col-3">
              <div className="copay-chart color-2 fw-bold text-dark-gray fs-18 pe-2 pb-2"><span></span>ACT</div>
            </div>
            <div className="col-3">
              <div className="copay-chart color-3 fw-bold text-dark-gray fs-18 pe-2 pb-2"><span></span>VIC</div>
            </div>
            <div className="col-3">
              <div className="copay-chart color-4 fw-bold text-dark-gray fs-18 pe-2 pb-2"><span></span>TAS</div>
            </div>
            <div className="col-3">
              <div className="copay-chart color-5 fw-bold text-dark-gray fs-18 pe-2 pb-2 pb-lg-0"><span></span>QLD</div>
            </div>
            <div className="col-3">
              <div className="copay-chart color-6 fw-bold text-dark-gray fs-18 pe-2 pb-lg-0"><span></span>SA</div>
            </div>
            <div className="col-3">
              <div className="copay-chart color-7 fw-bold text-dark-gray fs-18 pe-2 pb-lg-0"><span></span>WA</div>
            </div>
            <div className="col-3">
              <div className="copay-chart color-8 fw-bold text-dark-gray fs-18 pe-2 pb-lg-0"><span></span>NT</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoPayEnrolledCliniciansChart;
