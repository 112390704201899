import LogoInnerImg from "../../images/logo-inner.svg";
import clinicalEnrollImg from '../../images/clinical-enrolled-icon.svg';
import patinetEnrollImg from '../../images/patient-enrolled-icon.svg';
import complianceIconImg from '../../images/compliance-icon.svg';
import calendarIconImg from '../../images/calendar-icon.svg';
import psychologySupportImg from '../../images/psychology-support.svg';
import physioIconImg from '../../images/physio-icon.svg'
import dietitianIconImg from '../../images/dietitian-icon.svg'
import PatientGenderChart from './PatientSupportChart/PatientGenderChart';
import { useEffect, useState } from "react";
import SupportPatientService from "../../services/SupportPatientService";
import { toast } from "react-toastify";
import PatientAgeChart from "./PatientSupportChart/PatientAgeChart";
import EnrolledCliniciansChart from "./PatientSupportChart/EnrolledCliniciansChart";
import PatientsEnrolledPerClinicianChart from "./PatientSupportChart/PatientsEnrolledPerClinicianChart";
import MonthlyTreatmentChartTwo from "./PatientSupportChart/MonthlyTreatmentChartTwo";
import DaysDispensingChart from "./PatientSupportChart/DaysDispensingChart";
import PharmacyListedPriceChart from "./PatientSupportChart/PharmacyListedPriceChart";
import NationalPatientSupportChart from "./PatientSupportChart/NationalPatientSupportChart";
import MetroPatientSupportChart from "./PatientSupportChart/MetroPatientSupportChart";
import NonMetroPatientSupportChart from "./PatientSupportChart/NonMetroPatientSupportChart";
import PharmaciesPerPatientChart from "./PatientSupportChart/PharmaciesPerPatientChart";
import CliniciansPerPatientChart from "./PatientSupportChart/CliniciansPerPatientChart";
import PatientEngagementChart from "./PatientSupportChart/PatientEngagementChart";

const PatientDashboard = () => {
    // const [loading, setLoading] = useState(false);
    // const [patinetData, setPatinetData] = useState([]);

    // useEffect(() => {
    //     setLoading(true);
    //     const fetchData = async () => {
    //         try {
    //             const patientSupportData = await SupportPatientService.getPatientSupportData();
    //             if (patientSupportData.data.data.length === 0) {
    //                 // toast.success(patientSupportData.data.message);
    //             } else {
    //                 setPatinetData(patientSupportData.data.data);
    //             }

    //             setLoading(false);
    //         } catch (error) {
    //             setLoading(false);
    //             toast.error(error);
    //         }
    //     };

    //     fetchData();
    // }, []);
    return (
        <>
            <header className="inner fixed-top">
                <nav className="navbar navbar-expand-lg bg-white">
                    <section className="container">
                        <div className="navigation d-flex align-items-end w-100">
                            <div className="w-50 logo-inner ms-auto">
                                <img src={LogoInnerImg} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </section>
                </nav>
            </header>
            <main className="inner-page">
                <section className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h1 className="text-uppercase">Key Metrics</h1>
                            <div className="spacer-3"></div>
                        </div>
                    </div>
                    <div className="row gx-2 row-gap-3">
                        <div className="col-md-6 col-lg-4">
                            <div className="bg-turquoise rounded-pill d-flex align-items-center justify-content-center h-254">
                                <img src={clinicalEnrollImg} alt="" />
                                <div className="ps-3">
                                    <h2 className="fw-bold fs-40 text-white mb-0 lh-1">850</h2>
                                    <p className="text-white fs-20 fw-bold mb-0 lh-1">clinicians enrolled</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="bg-turquoise rounded-pill d-flex align-items-center justify-content-center h-254">
                                <img src={patinetEnrollImg} alt="" />
                                <div className="ps-3">
                                    <h2 className="fw-bold fs-40 text-white mb-0 lh-1">2,500</h2>
                                    <p className="text-white fs-20 fw-bold mb-0 lh-1">patients enrolled</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="bg-turquoise rounded-pill d-flex align-items-center justify-content-center h-254">
                                <img src={complianceIconImg} alt="" />
                                <div className="ps-3">
                                    <h2 className="fw-bold fs-40 text-white mb-0 lh-1">82%</h2>
                                    <p className="text-white fs-20 fw-bold mb-0 lh-1">12-month compliance</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="bg-turquoise rounded-pill d-flex align-items-center justify-content-center h-254">
                                <img src={calendarIconImg} alt="" />
                                <div className="ps-3 flex-basis-0">
                                    <h2 className="fw-bold fs-40 text-white mb-0 lh-1">32</h2>
                                    <p className="text-white fs-20 fw-bold mb-0 lh-1"><span className="text-nowrap">days between <br /> dispensing</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="bg-turquoise rounded-pill d-flex align-items-center justify-content-center h-254">
                                <img src={psychologySupportImg} alt="" />
                                <div className="ps-3 flex-basis-0">
                                    <h2 className="fw-bold fs-40 text-white mb-0 lh-1">50%</h2>
                                    <p className="text-white fs-20 fw-bold mb-0 lh-1">Psychology support</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="bg-turquoise rounded-pill d-flex align-items-center justify-content-center h-254">
                                <img src={physioIconImg} alt="" />
                                <div className="ps-3 flex-basis-0">
                                    <h2 className="fw-bold fs-40 text-white mb-0 lh-1">30%</h2>
                                    <p className="text-white fs-20 fw-bold mb-0 lh-1">Physiotherapy support</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <div className="bg-turquoise rounded-pill d-flex align-items-center justify-content-center h-254">
                                <img src={dietitianIconImg} alt="" />
                                <div className="ps-3 flex-basis-0">
                                    <h2 className="fw-bold fs-40 text-white mb-0 lh-1">20%</h2>
                                    <p className="text-white fs-20 fw-bold mb-0 lh-1">Dietician <br />support</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="spacer-8"></div>
                    <div className="row">
                        <PatientGenderChart />
                        <div className="spacer-3 d-md-none"></div>
                        <PatientAgeChart />
                    </div>
                    <div className="spacer-3"></div>
                    <div className="row">
                        <PatientEngagementChart />
                    </div>
                    <div className="spacer-3"></div>
                    <div className="row">
                        <EnrolledCliniciansChart />
                        <div className="spacer-3 d-md-none"></div>
                        <PatientsEnrolledPerClinicianChart />
                    </div>
                    <div className="spacer-3"></div>
                    <MonthlyTreatmentChartTwo />
                    <div className="spacer-3"></div>
                    <div className="row">
                        <DaysDispensingChart />
                        <div className="spacer-3 d-md-none"></div>
                        <PharmacyListedPriceChart />
                    </div>
                    <div className="spacer-3"></div>
                    <div className="row">
                        <NationalPatientSupportChart />
                        <div className="spacer-3 d-md-none"></div>
                        <MetroPatientSupportChart />
                    </div>
                    <div className="spacer-3"></div>
                    <div className="row">
                      <NonMetroPatientSupportChart />
                        <div className="spacer-3 d-md-none"></div>
                        <PharmaciesPerPatientChart />
                    </div>
                    <div className="spacer-3"></div>
                    <div className="row">
                        <CliniciansPerPatientChart/>
                    </div>
                    <div className="spacer-8"></div>
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <a href="/patient-support-welcome" className="btn btn-blue btn-default">CLOSE</a>
                        </div>
                    </div>
                    <div className="spacer-5"></div>
                </section>
            </main>
        </>
    )
}

export default PatientDashboard