import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { fontSizePatientEnrolled, getPadding, ResponsiveLableSize } from '../../../common/commonFun';

// Register required Chart.js components and plugins
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const CoPayPatientsEnrolledPerClinicianChart = () => {
  const data = {
    labels: [
      ['1', 'Patient'],
      ['2', 'Patients'],
      ['3', 'Patients'],
      ['4', 'Patients'],
      ['5+', 'Patients'],
   ],
   datasets: [{
    label: 'Clinicians',
    data: [85, 23, 18, 12, 5],
    backgroundColor: 'rgba(107, 186, 189, 1)',
    borderWidth: 1,
 }]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: getPadding(),
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide vertical grid lines
        },
        ticks: {
          font: {
            family: 'Open Sans',
            weight: 400,
            size: ResponsiveLableSize(),
          },
          autoSkip: false,
        },
        border: {
          color: '#858586',
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: 100,
        ticks: {
           stepSize: 20,
           font: {
              family: 'Open Sans',
              weight: 500,
              size: ResponsiveLableSize()
           }
        },
        border: {
           color: '#858586',
        },
        title: {
           display: true,
           text: 'Number of patients',
           color: '#6D6F71',
           font: {
              family: 'Open Sans',
              weight: 400,
              size: 16,
           },
           position: 'right',
           rotation: 0,
           padding: 20,
        },
     }
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'end',
        labels: {
          boxHeight: 20,
          boxWidth: 20,
          padding: 0,
          font: {
            family: 'Open Sans',
            size: 16,
            weight: 400,
          },
          color: '#54667A',
        },
      },
      datalabels: {
        anchor: (context) => {
          const data = context.chart.data.datasets[0].data;
          return data[context.dataIndex] > 12 ? 'end' : 'end'; // Anchor to end if >10, else center
        },
        align: (context) => {
          const data = context.chart.data.datasets[0].data;
          return data[context.dataIndex] > 12 ? 'start' : 'end'; // Align to start if >10, else end
        },
        color: (context) => {
          const data = context.chart.data.datasets[0].data;
          return data[context.dataIndex] > 12 ? '#fff' : '#2d9b9f'; // White for >10, Black for ≤10
        },
        font: {
          family: 'Open Sans',
          size: fontSizePatientEnrolled(),
          weight: 700,
        },
        offset: 10,
        formatter: (value, context) => {
          return `${value}%`; // Display percentage
        },
      },
    },  
  };

  return (
    <div className="width-lg-55">
      <div className="card rounded-0 border-dark-700 h-100 py-4">
        <div className="card-header bg-transparent border-0">
          <h3 className="text-secondary fw-bold fs-30 text-center">Patients enrolled per <br/> clinician</h3>
        </div>
        <div className="card-body">
          <div>
            <Bar data={data} options={options} id="coPayPatientEnrolled" width="100%" height="440" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoPayPatientsEnrolledPerClinicianChart;
